<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-row
                    style="flex-wrap: wrap; padding: 16px 16px 0 16px; background: #f4f7fa; margin-bottom: 15px"
                    class="flex"
                >
                    <el-col :span="6" style="margin-bottom: 16px">
                        <div>
                            <el-input placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select
                                    style="width: 120px"
                                    v-model="search_type"
                                    slot="prepend"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in searchTypeEnum"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </el-col>

                    <el-button style="margin-left: 24px; margin-bottom: 16px" type="primary" @click.stop="handleSubmit"
                        >搜索</el-button
                    >
                    <el-button @click.stop="handleClear">清空</el-button>
                </el-row>
                <el-table :data="tableData">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column label="渠道商编号" prop="code"></el-table-column>
                    <el-table-column label="渠道商名称" prop="name" min-width="160"></el-table-column>
                    <el-table-column label="登录手机号" prop="account"></el-table-column>
                    <el-table-column label="客户数" prop="custNum"> </el-table-column>
                    <el-table-column label="最后登录时间" prop="lastLoginTimeName"> </el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                tableData: [],
                search_value: '',

                search_type: 1,
                pageNum: 1,
                pageSize: 10,
                total: 0,

                searchTypeEnum: [
                    { name: '账号名称', value: 1 },
                    { name: '渠道商编号', value: 2 },
                    { name: '手机号', value: 3 },
                ],

                loading: false,
            };
        },
        created() {
            this.fetchData();
        },
        mounted() {},
        watch: {},
        computed: {},
        methods: {
            handleSubmit() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.fetchData();
            },
            handleClear() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.search_value = '';

                this.search_type = 1;
                this.fetchData();
            },
            async fetchData() {
                const loading = this.$loading();
                const res = await this.$axios({
                    url: '/api/channel/queryChannelAccountLogList',
                    method: 'post',
                    data: {
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        searchType: this.search_type,
                        searchValue: this.search_value,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.accountLogList || [];
                    this.total = res.data.count || 0;
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },
            // 翻页相关
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.fetchData();
            },
            // 翻页相关
            handlePageNoChange(no) {
                this.pageNum = no;
                this.fetchData();
            },
        },
    };
</script>
<style lang="scss" scoped>
    .empidselect {
        width: 340px;
    }
</style>
